import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Scrollspy from "react-scrollspy";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Helmet } from "react-helmet";

const Header = () => {
  return (
    <section id="header">
      <Helmet>
        <html lang="de" />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dipl. Sozialpädagoge, Systemischer Berater und Therapeut (SG), Suchttherapeut (GVS Deutschland) und Betriebswirt der Sozialwirtschaft. Arbeitsschwerpunkte: Systemische Supervision, Coaching, Mediation, Paarberatung, Suchttherapie und Jugendhilfe mit verhaltensorginellen Jugendlichen."
        />
        <title>Bernd Funk</title>
      </Helmet>
      <header>
        <span className="image avatar">
          <StaticImage
            src="../images/bernd_funk_portrait.jpg"
            alt="Bernd Funk"
          />
        </span>
        <h1 id="logo">
          <Link to="/">Bernd Funk</Link>
        </h1>
        <p>Systemisches Arbeiten lädt ein, die Perspektive zu erweitern</p>
      </header>
      <nav id="nav">
        <Scrollspy
          items={["start", "arbeitsschwerpunkte", "zur_person", "kontakt"]}
          currentClassName="active"
        >
          <li>
            <AnchorLink href="#start">Start</AnchorLink>
          </li>
          <li>
            <AnchorLink href="#arbeitsschwerpunkte">
              Arbeitsschwerpunkte
            </AnchorLink>
          </li>
          <li>
            <AnchorLink href="#zur_person">Zur Person</AnchorLink>
          </li>

          <li>
            <AnchorLink href="#kontakt">Kontakt</AnchorLink>
          </li>
        </Scrollspy>
      </nav>
      <footer>
        <ul className="icons">
          <li>
            <a
              href="mailto:info@bernd-funk.net"
              className="icon solid fa-envelope"
              aria-label="E-Mail"
            >
              <span className="label">E-Mail</span>
            </a>
          </li>
          <li>
            <a
              href="tel:+49 15117199969"
              className="icon solid fa-mobile"
              aria-label="Mobil"
            >
              <span className="label">Mobil</span>
            </a>
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=+4915117199969"
              target="_blank"
              rel="noreferrer"
              className="icon brands fa-whatsapp"
              aria-label="Whatsapp"
            >
              <span className="label">WhatsApp</span>
            </a>
          </li>
        </ul>
      </footer>
    </section>
  );
};

export default Header;
