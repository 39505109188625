import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Intro = () => {
  return (
    <section id="start">
      <StaticImage src="../images/head.jpg" alt="" />
      <div className="container">
        <header className="major">
          <h2 style={{ margin: 0 }}>Bernd Funk</h2>
          <div>
            <p>
              Dipl. Sozialpädagoge
              <br />
              Systemischer Berater und Therapeut (SG) <br />
              Suchttherapeut (GVS Deutschland) <br />
              Betriebswirt der Sozialwirtschaft
            </p>
          </div>
        </header>
        <p>
          <em>
            "Wenn etwas funktioniert, mache mehr davon. Wenn etwas nicht
            funktioniert, mache etwas anders."
          </em>
          <br />
          <sub>Insoo Kim Berg</sub>
        </p>
      </div>
    </section>
  );
};

export default Intro;
