import React from "react";

const SystemBuster = () => {
  return (
    <div className="container" id="jugendhilfe">
      <header>
        <h4>Jugendhilfe mit verhaltensorginellen Jugendlichen</h4>
        <p>
          Systemsprenger werden Klienten der Pädagogik und Psychiatrie genannt,
          die sich nicht ins Hilfesystem zu integrieren scheinen. Sie wechseln
          häufig die Hilfen und die Hilfeorte, hinterlassen zum Teil große
          Schäden und halten die beteiligten Fachkräfte über die Maßen „in
          Atem“. Systemsprenger sind Personen, die aufgrund ihrer besonderen
          Verhaltensauffälligkeiten nur schwer in Maßnahmen der Kinder- und
          Jugendhilfe respektive der Behindertenhilfe integriert werden können.
          Sie sind ein großes Problem des gesamten sozialen Bereiches.
          Infolgedessen werden sie entweder von Einrichtung zu Einrichtung
          durchgereicht oder sie ziehen sich ganz zurück, fallen aus dem
          sozialen Netz, werden obdachlos oder straffällig.
        </p>
      </header>
    </div>
  );
};

export default SystemBuster;
