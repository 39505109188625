import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Training = () => {
  return (
    <div className="container">
      <header>
        <h4>Fortbildung</h4>
        <p>
          Fortbildungen helfen neue Sichtweisen zu finden, frischt Wissen auf
          und erlaubt, auf dem neuesten Stand des Wissens zu sein.
        </p>
      </header>
      <div className="row">
        <div className="col-6 col-12-xsmall">
          <StaticImage src="../images/training.jpg" alt="Fortbildung" />
        </div>
        <div className="col-6 col-12-xsmall">
          <b>Fortbildungsthemen</b>
          <ul>
            <li>systemisches Arbeiten</li>
            <li>ressourcenorientiertes Arbeiten</li>
            <li>Kinderschutz in Institutionen</li>
            <li>Gewaltdynamiken in Familien</li>
            <li>Kindeswohlgefährdung</li>
            <li>Sucht und Familie</li>
            <li>Sucht und Dynamiken der Gewalt</li>
            <li>Problematischer Internet Konsum</li>
            <li>Sucht und Co-Abhängigkeit</li>
            <li>Arbeit mit Systemsprengern</li>
            <li>Systemisches Videotraining in der Arbeit mit gewaltbereiten Jugendlichen</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Training;
