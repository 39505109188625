import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Supervision from "./supervision";
import Mediation from "./mediation";
import Training from "./training";
import CoupleCounseling from "./couplecounseling";
import SystemBuster from "./systembuster";

const Work = () => {
  return (
    <section id="arbeitsschwerpunkte">
      <div className="container">
        <h3>Arbeitsschwerpunkte</h3>
        <p>Meine Arbeitsschwerpunkte umfassen die folgenden Bereiche:</p>
        <ul className="feature-icons">
          <li className="icon solid fa-eye">
            <AnchorLink href="#systemische_supervision">
              Systemische Supervision
            </AnchorLink>
          </li>
          <li className="icon solid fa-info">
            <AnchorLink href="#coaching">Coaching</AnchorLink>
          </li>
          <li className="icon solid fa-comments">
            <AnchorLink href="#mediation">Mediation</AnchorLink>
          </li>
          <li className="icon solid fa-user-plus">
            <AnchorLink href="#paarberatung">Paarberatung</AnchorLink>
          </li>
          <li className="icon solid fa-ban">Suchttherapie</li>
          <li className="icon solid fa-bomb">
            <AnchorLink href="#jugendhilfe">
              Jugendhilfe mit verhaltensorginellen Jugendlichen
            </AnchorLink>
          </li>
        </ul>
      </div>
      <Supervision />
      <Mediation />
      <Training />
      <CoupleCounseling />
      <SystemBuster />
    </section>
  );
};

export default Work;
