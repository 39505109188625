import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const About = () => {
  return (
    <section id="zur_person">
      <div className="container">
        <h3>Zur Person</h3>
        <div className="features">
          <article>
            <div className="image" style={{ verticalAlign: "top" }}>
              <StaticImage src="../images/university.jpg" alt="" />
            </div>
            <div className="inner">
              <h4>Studium und Ausbildung</h4>
              <ul>
                <li>
                  <b>3/1980 – 3/1983 </b>
                  <br />
                  Studium der Sozialpädagogik an der Ev. Fachhochschule des
                  Rauhen Hauses in Hamburg
                </li>
                <li>
                  <b>4/1983 – 3/1984</b>
                  <br /> Berufspraktikum im Jugendamt Hamburg – Schnelsen
                </li>
              </ul>
            </div>
          </article>
          <article>
            <div className="image" style={{ verticalAlign: "top" }}>
              <StaticImage src="../images/mountain.jpg" alt="" />
            </div>
            <div className="inner">
              <h4>Berufspraxis</h4>
              <ul>
                <li>
                  <b>6/1978 – 12/1978</b>
                  <br /> Großkundenbetreuer in der Weingroßhandlung Fuchs in
                  Westerland auf Sylt
                </li>
                <li>
                  <b>7/ 1984 – 9/1990</b>
                  <br /> Pädagogischer Leiter der Behinderteneinrichtung „Elbe
                  Werkstätten“ in Hamburg – Altona
                </li>
                <li>
                  <b>10/1990 – 12/1997</b>
                  <br /> Suchttherapeut und Fachbereichsleiter der Beratungs –
                  und Behandlungsstelle des Kirchenkreises Südtondern in Niebüll
                </li>
                <li>
                  <b>01/1998 – 10/2006</b>
                  <br /> Leiter des Beratungs- und Behandlungszentrums (BBZ-
                  Niebüll) mit den Inseln Föhr und Amrum
                </li>
                <li>
                  <b>11/2006 – 11/2012</b>
                  <br /> Geschäftsführer des Diakonischen Werkes Südtondern
                  gGmbh
                </li>
                <li>
                  <b>12/2012 – 12/2014</b>
                  <br /> Leitung Jugendhilfenetzwerk und Leitung von
                  Fachkliniken für Suchtkranke
                </li>
                <li>
                  <b>1/2015 – 03/2017</b>
                  <br /> Leitung Psychologisches Beratungsstelle in Baden –
                  Württemberg und Koordinator Jugendhilfe
                </li>
                <li>
                  <b>04/2017 bis heute</b>
                  <br />Leitung Jugendhilfeeinrichtung beim Träger
                  MIO in Westre / Nordfriesland
                </li>
              </ul>
            </div>
          </article>
          <article>
            <div className="image" style={{ verticalAlign: "top" }}>
              <StaticImage src="../images/puzzle.jpg" alt="" />
            </div>
            <div className="inner">
              <h4>Ausgewählte Weiterbildungen</h4>
              <ul>
                <li>
                  <b>1986 -1988</b>
                  <br /> Ausbildung im Institut für Tanztherapie in Hamburg
                </li>
                <li>
                  <b>9/1991 – 1/1995</b>
                  <br /> Ausbildung zum Suchttherapeuten in Kassel
                </li>
                <li>
                  <b>11/1998 – 11/2000</b>
                  <br /> Ausbildung zum Betriebswirt der Sozialwirtschaft –
                  Diplom in Kiel
                </li>
                <li>
                  <b>2004 /2005</b>
                  <br /> Ausbildung zum Systemischen Berater und zum Video-
                  Trainer
                </li>
                <li>
                  <b>2009 /2010</b>
                  <br /> Weiterbildung „Häusliche Gewalt“ Männerbüro Hannover
                </li>
                <li>
                  <b>2014 – 2015</b>
                  <br /> Ausbildung zum Qualitätsmanager (Deutsche Gesellschaft
                  für Qualität in Frankfurt)
                </li>
                <li>
                  <b>2016 – 2017</b>
                  <br /> Ausbildung zum Supervisor und Mediator am Helm –
                  Stierlin – Institut in Heidelberg
                </li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default About;
