import React from "react";
import { navigate } from "gatsby";

const Contact = () => {
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        "absender": formData.get("absender"),
        "nachricht": formData.get("nachricht"),
        "email": formData.get("email"),
        "betreff": formData.get("betreff"),
        "datenschutz": formData.get("datenschutz"),
      }),
    })
      .then(() => navigate("/success/"))
      .catch((error) => alert(error));
  };

  return (
    <section id="kontakt">
      <div className="container">
        <h3>Kontakt</h3>
        <p>
          Haben Sie Fragen zu mir oder meiner Arbeit? Schreiben Sie mir eine
          Nachricht über das Kontaktformular, per E-Mail oder rufen einfach an.
          Gerne beantworte ich Ihr Anliegen.
        </p>
        <ul className="feature-icons">
          <li className="icon solid fa-envelope">
            <a href="mailto:info@bernd-funk.net">
              info@bernd-funk.net<span className="label"></span>
            </a>
          </li>
          <li className="icon solid fa-mobile">
            <a href="tel:+49 15117199969">
              <span className="label">Mobil +49 15117199969</span>
            </a>
          </li>
          <li className="icon solid fa-fax">
            <a href="tel:+49 15117199969">
              <span className="label">Fax +49 4666 9893-124</span>
            </a>
          </li>
          <li className="icon brands fa-whatsapp">
            <a
              href="https://api.whatsapp.com/send?phone=+4915117199969"
              target="_blank"
              rel="noreferrer"
            >
              <span className="label">WhatsApp</span>
            </a>
          </li>
        </ul>

        <form
          data-netlify="true"
          name="kontaktformular"
          method="post"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="kontaktformular" />

          <div className="row gtr-uniform">
            <div className="col-6 col-12-xsmall">
              <input
                type="text"
                name="absender"
                id="absender"
                placeholder="Name"
                required="required"
              />
            </div>
            <div className="col-6 col-12-xsmall">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="E-Mail"
                required="required"
              />
            </div>
            <div className="col-12">
              <input
                type="text"
                name="betreff"
                id="betreff"
                placeholder="Betreff"
              />
            </div>
            <div className="col-12">
              <textarea
                name="nachricht"
                id="nachricht"
                placeholder="Nachricht"
                required="required"
                rows="6"
              ></textarea>
            </div>
            <div className="col-12">
              <ul className="actions">
                <li>
                  <input
                    type="checkbox"
                    id="datenschutz"
                    name="datenschutz"
                    required="required"
                  />
                  <label htmlFor="datenschutz">
                    Ich habe die{" "}
                    <a href="/datenschutz" target="_blank">
                      Datenschutzerklärung
                    </a>{" "}
                    zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und
                    Daten zur Beantwortung meiner Anfrage elektronisch erhoben
                    und gespeichert werden. Hinweis: Sie können Ihre
                    Einwilligung jederzeit für die Zukunft per E-Mail an
                    info@bernd-funk.net widerrufen.
                  </label>
                </li>
                <li>
                  <input type="submit" className="primary" />
                </li>
              </ul>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
