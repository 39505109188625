import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const CoupleCounseling = () => {
  return (
    <div className="container" id="paarberatung">
      <header>
        <h4>Paarberatung</h4>
        <p>
          Manchmal gerät die Welt aus den Fugen, wenn die Liebe verloren zu
          gehen scheint. Es gibt in jeder Paarbeziehung Krisen. Das Gute daran
          ist, dass sie lösbar sind.
        </p>
      </header>
      <div className="row">
        <div className="col-6 col-12-xsmall">
          <StaticImage src="../images/couple.jpg" alt="Paarberatung" />
        </div>
        <div className="col-6 col-12-xsmall">
          <b>Paarberatung</b>
          <ul>
            <li>Aus alten Mustern ausbrechen</li>
            <li>Streiten lernen</li>
            <li>Gehen oder bleiben?</li>
            <li>Trennungsberatung</li>
            <li>Die Liebe, der Alltag und wir</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CoupleCounseling;
