import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Mediation = () => {
  return (
    <div className="container" id="mediation">
      <header>
        <h4>Mediation</h4>
        <p>
          Mediation ist ein strukturiertes Verfahren zur konstruktiven Beilegung
          eines Konfliktes in beruflichen Zusammenhängen,
          Nachbarschaftskonflikten, Paarbeziehungen, familiären
          Auseinandersetzungen und Dissenzen in der freien Wirtschaft.
        </p>
      </header>
      <div className="row">
        <div className="col-6 col-12-xsmall">
          <StaticImage src="../images/mediation.jpg" alt="Paarberatung" />
        </div>
        <div className="col-6 col-12-xsmall">
          <b>Mediation</b>
          <ul>
            <li>
              ist eine einvernehmliche, außergerichtliche Lösungeines Konfliktes
            </li>
            <li>bedeutet, den Konflikt von der Person zu trennen</li>
            <li>
              anerkennt unterschiedliche Bedürfnisseund Interessen des oder
              derKonfliktpartner/innen
            </li>
            <li>erkennt Emotionen hinter dem Konflikt</li>
            <li>ist allparteilich</li>
            <li>stärkt Eigenverantwortlichkeit</li>
            <li>ist eine win-win Lösung</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Mediation;
