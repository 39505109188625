import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Supervision = () => {
  return (
    <div className="container" id="systemische_supervision">
      <header>
        <h4>Supervision & Coaching</h4>
        <p>
          Supervision ist ein hilfreiches Instrument Arbeit zu reflektieren,
          Handlungsmöglichkeiten zu erweitern, den Blickwinkel zu vergrößern und
          professionelles Handeln kontinuierlich zu überprüfen. Im beruflichen
          Alltag können wir uns verstricken, manchmal geht es einfach nicht
          weiter oder wir fühlen uns ausgebrannt. Hier biete ich Ihnen Raum, um
          "Dinge los zu werden" und sich neu zu sortieren.
        </p>
      </header>
      <div className="row">
        <div className="col-6 col-12-xsmall">
          <StaticImage src="../images/supervision.jpg" alt="Supervision" />
        </div>
        
        <div className="col-6 col-12-xsmall">
          {" "}
          <b>Supervision</b>
          <ul>
            <li>macht Stärken sichtbar</li>
            <li>entlastet den Arbeitsalltag</li>
            <li>hilft Arbeitsabläufe zu verbessern</li>
            <li>fördert das Miteinandereines eines Teams</li>
            <li>ist Psychohygiene</li>
            <li>erhöht Handlungskompetenzen</li>
            <li>verbessert damit nachhaltigdie Arbeitszufriedenheit</li>
          </ul>
          <b id="coaching">Coaching</b>
          <ul>
            <li>begleitet berufliche Entwicklungund Veränderung</li>
            <li>hilft Lebensthemen zu klären</li>
            <li>hilft neue Handlungsoptionen zu entwickeln</li>
            <li>begleitet Thementwicklung </li>
            <li>untersützt kreatives Arbeiten</li>
          </ul>
          <b>Systemische Supervision & Coaching</b>
          <ul>
            <li>für Einzelpersonen</li>
            <li>für Teams</li>
            <li>für Gruppen bis zu 12 Personen</li>
            <li>als Einzelangebot</li>
            <li>als fortlaufender Prozess</li>
            <li>als Krisenintervention</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Supervision;
