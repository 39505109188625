import * as React from "react";
import Header from "../components/header";
import Intro from "../components/intro";
import About from "../components/about";
import Work from "../components/work";
import Contact from "../components/contact";
import Footer from "../components/footer";

const IndexPage = () => {
  const handleSidebar = () => {
    const bodyClassList = document.body.classList;
    if (bodyClassList.contains("header-visible")) {
      bodyClassList.remove("header-visible");
    } else {
      bodyClassList.add("header-visible");
    }
  };
  return (
    <main>
      <Header />

      <div id="wrapper">
        <div id="main">
          <Intro />

          <Work />

          <About />

          <Contact />
        </div>
      </div>
      <Footer />
      <div id="titleBar">
        <div
          className="toggle"
          role="button"
          onClick={handleSidebar}
          onKeyDown={handleSidebar}
          tabIndex={0}
          aria-label="Open Navigation"
        ></div>
        <span className="title">Bernd Funk</span>
      </div>
    </main>
  );
};

export default IndexPage;
